import { toast } from "@/components/Toaster";
import { datadogRum } from "@datadog/browser-rum";
import { type ZslTemplate } from "@/server/services/zslapi";

interface TemplateError {
  errors?: Array<{
    status?: number;
    detail?: string;
  }>;
}

export function handleTemplateError(
  error: TemplateError,
  template: ZslTemplate
) {
  datadogRum.addError(error, {
    template_type: template.name,
    error_type: "template_execution_error",
  });

  const isZapStepLimit = error.errors?.some(
    (e) =>
      e.status === 426 ||
      e.detail?.includes("more steps than the maximum amount your plan allows")
  );

  if (isZapStepLimit) {
    toast.error({
      message:
        "Your plan doesn't support this many Zap steps. Please upgrade to use this template.",
    });
  } else {
    toast.error({
      message: "Failed to create interface from template",
    });
  }
}
